import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import Survey from "./pages/Survey";
import Resume from "./pages/Resume";
import Success from "./pages/Success";

// partials

function App() {
  // event listener used to conditionally render based on window size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 640);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Router>
      <Switch>
        <Route path="/survey">
          <Survey />
        </Route>
        <Route path="/resume/:id">
          <Resume />
        </Route>
        <Route path="/success">
          <Success />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
