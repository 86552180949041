import React from "react";

export default function InputField({
  isTextarea,
  isCheckbox,
  labelFor,
  labelName,
  isRequired,
  labelStyle,
  inputType,
  inputName,
  inputId,
  theInput,
  setTheInput,
  inputStyle,
  maxLength,
  rows,
  count,
  hasError,
  error,
  autocomplete,
}) {
  return (
    <div>
      {isTextarea ? (
        <>
          <label for={labelFor} className={labelStyle}>
            <div>
              {labelName}
              {isRequired && <span className="text-red-600"> *</span>}
            </div>
            {count !== undefined && (
              <>
                {count === maxLength ? (
                  <div className="text-red-600">
                    {count + "/" + maxLength} Zeichen
                  </div>
                ) : (
                  <div>{count + "/" + maxLength} Zeichen</div>
                )}
              </>
            )}
          </label>
          <textarea
            rows={rows}
            maxLength={maxLength}
            type={inputType}
            name={inputName}
            id={inputId}
            value={theInput}
            onChange={(e) => setTheInput(e.target.value)}
            required={isRequired}
            className={inputStyle}
          />
        </>
      ) : (
        <>
          {isCheckbox ? (
            <>
              <label className={labelStyle}>
                <span class="text-gray-700">
                  {labelName}
                  {isRequired && <span className="text-red-600"> *</span>}
                </span>
                <input
                  type="checkbox"
                  className={inputStyle}
                  checked={theInput}
                  required={isRequired}
                  onChange={(e) => setTheInput(e.target.checked)}
                />
              </label>
            </>
          ) : (
            <>
              <label for={labelFor} className={labelStyle}>
                <div>
                  {labelName}
                  {isRequired && <span className="text-red-600"> *</span>}
                </div>
                {count !== undefined && (
                  <>
                    {count === maxLength ? (
                      <div className="text-red-600">
                        {count + "/" + maxLength} Zeichen
                      </div>
                    ) : (
                      <div>{count + "/" + maxLength} Zeichen</div>
                    )}
                  </>
                )}
                {error !== undefined && hasError && !error && (
                  <div className="text-red-600">In numerischer Form bitte</div>
                )}
              </label>
              <input
                rows={rows}
                maxLength={maxLength}
                type={inputType}
                name={inputName}
                autocomplete={autocomplete ? autocomplete : "on"}
                id={inputId}
                value={theInput}
                onChange={(e) => setTheInput(e.target.value)}
                required={isRequired}
                className={inputStyle}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
