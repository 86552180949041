import React from "react";

export default function Button({
  onClick,
  buttonText,
  type,
  bgColor,
  bgHoverColor,
  borderColor,
  textColor,
  fontWeight,
  px,
  py,
  mt,
  ml,
  width,
  image,
  imageAlt,
  ringColor,
}) {
  return type ? (
    <button
      className={
        "duration-200 ease-in-out transition hover:scale-110 mt-" +
        mt +
        " ml-" +
        ml +
        " bg-" +
        bgColor +
        " border border-" +
        borderColor +
        " rounded-md py-" +
        py +
        " px-" +
        px +
        " flex items-center justify-center text-base font-" +
        fontWeight +
        " text-" +
        textColor +
        " hover:bg-" +
        bgHoverColor +
        " focus:outline-none"
          .concat(width === "full" && " w-full")
          .concat(
            ringColor &&
              " focus:ring-2 focus:ring-offset-2 focus:ring-" + ringColor
          )
      }
      type={type}
    >
      {image && (
        <span>
          <img
            src={image}
            alt={imageAlt}
            className="w-8 mr-3 object-center object-cover"
          />
        </span>
      )}
      {buttonText}
    </button>
  ) : (
    <button
      className={
        "duration-200 ease-in-out transition hover:scale-110 mt-" +
        mt +
        " ml-" +
        ml +
        " bg-" +
        bgColor +
        " border border-" +
        borderColor +
        " rounded-md py-" +
        py +
        " px-" +
        px +
        " flex items-center justify-center text-base font-" +
        fontWeight +
        " text-" +
        textColor +
        " hover:bg-" +
        bgHoverColor +
        " focus:outline-none"
          .concat(width === "full" && " w-full")
          .concat(
            ringColor &&
              " focus:ring-2 focus:ring-offset-2 focus:ring-" + ringColor
          )
      }
      onClick={onClick}
    >
      {image && (
        <span>
          <img
            src={image}
            alt={imageAlt}
            className="w-8 mr-3 object-center object-cover"
          />
        </span>
      )}
      {buttonText}
    </button>
  );
}
