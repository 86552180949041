import NODE_API from "./NODE_API.js";

class applicationService {
  getApplication(applicationId) {
    const request = NODE_API.get("/applications/" + applicationId);
    return Promise.resolve(request);
  }

  saveApplication(applicationBody) {
    const request = NODE_API.post("/applications", applicationBody);
    return Promise.resolve(request);
  }
}

export default new applicationService();
