import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import baseURL from "../BASE_URL";

export default function Success() {
  const checkTransition = { duration: 4, delay: 0.5, ease: "backOut" };

  const [counter, setCounter] = useState(15);

  useEffect(() => {
    if (counter === 0) {
      window.location = "https://stiftungbuergerfuermuenster.de";
    } else {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  return (
    <div className="flex items-center justify-center">
      <div className="px-16 md:px-32 py-8 md:py-16 shadow-lg rounded-lg mt-32 flex flex-col items-center justify-center">
        <div className="border-4 border-green-400 p-4 rounded-full shadow-lg w-max">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-32 w-32 text-green-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 13l4 4L19 7"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={checkTransition}
            />
          </svg>
        </div>
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 mt-8 text-center">
          Ihre Bewerbung war erfolgreich!
        </h2>
        {/* Back to Oberursel Card button */}
        <div className="mt-8">
          <button
            onClick={() => (window.location = "https://stiftungbuergerfuermuenster.de")}
            className="gap-x-3 mt-6 w-full bg-red-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <div className="h-6 w-6 rounded-full text-white border-2 text-sm flex items-center justify-center">
              {counter}
            </div>
            <p>Zur Stiftung Bürger für Münster</p>
          </button>
        </div>
        {/* Back to Oberursel Card button */}
        <div className="mt-8">
          <button
            onClick={() => (window.location = baseURL + "/survey")}
            className="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
            hover:bg-gray-200  
            bg-gray-100 
            text-gray-700 
            border duration-200 ease-in-out 
            border-gray-600 transition"
          >
            Weitere Bewerbung einreichen
          </button>
        </div>
      </div>
    </div>
  );
}
